import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { FaRunning } from 'react-icons/fa';
import useAnimate from '../../utils/hooks/useAnimate';
import Container from '../../components/Container';
import Button from '../../components/Button';
import Layout from '../../components/Layout';
import Stack from '../../components/Stack';
import pikachu from '../../images/sad.gif';
import styles from './404.module.scss';

export default function NotFoundPage() {
  const animate = useAnimate();
  return (
    <Layout className={classnames(animate && 'animate')} title='404'>
      <Container containerClassName={styles.container}>
        <Stack align='center' direction='column'>
          <img className={styles.text} alt='Sad Pikachu' src={pikachu} />
          <h1 className={styles.text}>404 | Page not found</h1>
          <p className={styles.text}>Your page broke out of the Pokeball!</p>
          <Stack className={styles.actions}>
            <Button as={Link} to='/' icon={{ left: FaRunning }}>
              Escape?
            </Button>
            <Button onClick={() => window.location.reload()}>;p</Button>
          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
}
